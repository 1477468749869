import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

import './LandingPageContent.css';

function LandingPageSuccess() {

  useEffect(() => {
    // After the component mounts, append the noscript iframe to the body
    const noscriptIframe = document.createElement('noscript');
    noscriptIframe.innerHTML = `
      <a href="https://fraudblocker.com" rel="nofollow">
        <img src="https://monitor.fraudblocker.com/ctrack.js?sid=2Xe3upnU6zWGV2ZPzNWLG" alt="Fraud Blocker" />
      </a>`;
    document.body.prepend(noscriptIframe);
  }, []);
  useEffect(() => {
    // After the component mounts, append the noscript iframe to the body
    const scriptIframe = document.createElement('script');
    scriptIframe.innerHTML = `
    (function () {
      var s = document.createElement("script"),
      h = document.head;
      s.async = 1;
      s.src = "https://monitor.fraudblocker.com/ctrack.js?sid=2Xe3upnU6zWGV2ZPzNWLG";
      h.appendChild(s);
    })();`;
    document.body.prepend(scriptIframe);
  }, []);

  const [isGradientRightToLeft, setIsGradientRightToLeft] = useState(false);
  const [isImageVisible, setIsImageVisible] = useState(true);

  const handleButtonClick = () => {
    setIsGradientRightToLeft(!isGradientRightToLeft);
    setIsImageVisible(!isImageVisible);
  };

  const gradientStyle = {
    background: isGradientRightToLeft
      ? 'linear-gradient(to left, #AA4098 50%, #D86F71 50%)'
      : 'linear-gradient(to right, #AA4098 50%, #D86F71 50%)',
  };

  return (
      <div className="App">
        <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-R5HV81E1QW"></script>
        <script async>
          {`window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'G-R5HV81E1QW');
          `}
        </script>
      </Helmet>
        <div className="upside">
            <div className="header">
                  <div className="logo">
                    {
                      isImageVisible ? ( <img alt='' src='%PUBLIC_URL%/../kid-arabic.png'/> ) : ( <img alt='' src='%PUBLIC_URL%/../kid-english.png'/> )
                    }
                  </div>
                  <div className="language" style={gradientStyle} onClick={handleButtonClick}>
                    <p>عر</p>
                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" strokeWidth="2" d="M12,23 C18.0751322,23 23,18.0751322 23,12 C23,5.92486775 18.0751322,1 12,1 C5.92486775,1 1,5.92486775 1,12 C1,18.0751322 5.92486775,23 12,23 Z M12,23 C15,23 16,18 16,12 C16,6 15,1 12,1 C9,1 8,6 8,12 C8,18 9,23 12,23 Z M2,16 L22,16 M2,8 L22,8"></path></svg>
                    <p>en</p>
                  </div>
            </div>

            <div className="title">
              {
                isImageVisible ? ( <h1>امنح أطفالك الحرية الرقمية الآمنة: مع خدمة امان الاطفال، عالم رقمي مشرق لأطفالك</h1> ) : ( <h1>Give your kids safe digital freedom: With Kid Security, a bright digital world for your kids</h1> )
              }
              <div className="photo">
                <img alt='' src='%PUBLIC_URL%/../kid-security.png'/> 
              </div>
            </div>
        </div>

        <div className="content" style={{textAlign: "center", fontSize: "13px"}}>
          {
            isImageVisible ? ( <h1>شكراً لاشتراكك في خدمة أمان الأطفال. يمكنك الأن الحصول على المحتوى أو تنزيل التطبيق</h1> ) : ( <h1>Thank you for subscribing to Kid Security service. You can now access the content or download the APP</h1> )
          }
        </div>
        
        </div>
  );
}                
export default LandingPageSuccess;